@use '../vars';

@mixin css
{
    //############## GRID ##############

    ng-grid
    {
        div.css-grid-content-renderer
        {
            .content-renderer-body > div > div
            {
                padding: 3px;
                border-top: none;
                line-height: initial;
            }

            .content-renderer-header > div
            {
                border-color: var(--grid-oddRow-background);
                padding: 5px;
                border-width: 1px;
                border-style: solid;
                line-height: initial;
            }

            .fixed-header
            {
                white-space: nowrap;
                position: sticky;
                top: 0;
                z-index: 10;
                background-color: var(--grid-oddRow-background);
            }

            .header-content::first-letter
            {
                text-transform: uppercase;
            }

            .header-content
            {
                display: inline-block;
            }

            .content-renderer-body > div:nth-of-type(2n+1) > div
            {
                background-color: var(--grid-evenRow-background);
            }
        }

        table
        {
            width: 100%;
            max-width: 100%;
            background-color: transparent;
            border-spacing: 0;
            border-collapse: collapse;
    
            .header-ordering
            {
                padding-left: 4px;
            }

            .fixed-header
            {
                background-color: var(--grid-oddRow-background);
                white-space: nowrap;
                position: sticky;
                top: 0;
                z-index: 10;
            }

            & > thead > tr > th
            {
                border-color: var(--grid-oddRow-background);
                padding: 5px;
                vertical-align: bottom;
                border-width: 1px;
                border-style: solid;
                text-align: left;
            }
    
            & > tbody > tr > td
            {
                padding: 3px;
                vertical-align: middle;
            }

            & > tbody > tr:nth-of-type(2n+1)
            {
                background-color: var(--grid-evenRow-background);
            }
        }

        ng-basic-paging
        {
            margin-top: vars.$sizeSm;

            &>div
            {
                display: flex;
                flex-direction: row;

                &>ul
                {
                    margin: 0;
                    padding: 0;
                    flex: 1;
                    display: flex;

                    li
                    {
                        list-style: none;

                        a
                        {
                            padding: 5px 10px;
                            font-size: 0.9em;
                        }
                    }
                }

                &>div
                {
                    display: flex;
                    flex-direction: row;

                    .items-count
                    {
                        align-self: center;
                        line-height: 1em;
                    }

                    ul
                    {
                        display: flex;
                        flex-direction: row;
                        margin: 0;
                        padding: 0;

                        li
                        {
                            list-style: none;

                            a
                            {
                                padding: 5px 10px;
                                font-size: 0.9em;
                            }
                        }
                    }
                }
            }
        }

        @include paging-full-coloring('');
    }

    .highlight-block
    {
        ng-grid
        {
            div.css-grid-content-renderer
            {
                .content-renderer-header > div
                {
                    border-color: var(--hightlight-grid-header-background);
                }

                .fixed-header
                {
                    background-color: var(--hightlight-grid-header-background);
                    color: var(--hightlight-grid-header-foreground);
                }

                .content-renderer-body > div:nth-of-type(2n+1) > div
                {
                    background-color: var(--hightlight-grid-evenRow-background);
                }
            }

            @include paging-full-coloring('highlight-');
        }
    }
}

@mixin paging-coloring($name)
{
    li
    {
        a
        {
            background-color: var(--paging-onPrimary-#{$name}background);
            color: var(--paging-onPrimary-#{$name}foreground);
        }

        &.disabled a
        {
            background-color: var(--paging-onPrimary-disabled-#{$name}background);
            color: var(--paging-onPrimary-disabled-#{$name}foreground);
        }

        &.active a
        {
            background-color: var(--paging-onSecondary-#{$name}background);
            color: var(--paging-onSecondary-#{$name}foreground);
        }
    }
}

@mixin paging-full-coloring($name)
{
    ng-basic-paging
    {
        &>div
        {
            &>ul
            {
                @include paging-coloring($name);
            }
    
            &>div
            {
                ul
                {
                    @include paging-coloring($name);
                }
            }
        }
    }
}