@use '../vars';

@mixin css
{
    //############## TITLES ##############

    .page-title
    {
        background-color: var(--pageTitle-background);
        color: var(--pageTitle-foreground);
        padding: 20px 20px 10px;
        font-size: 1.5em;
        margin: vars.$sizeSm 0 0;
    }

    .section-title
    {
        margin-bottom: vars.$sizeXs;
        margin-left: vars.$sizeXs;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 500;
        font-size: 1.05em;
    }

    .subsection-title
    {
        margin-bottom: vars.$sizeXs;
        margin-left: vars.$sizeXs;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 500;
        font-size: 0.9em;
    }
}