@use 'sass:map';

@mixin buildThemeColors($theme)
{
    --page-background: #{map.get(map.get($theme, 'page'), 'background')};
    --page-foreground: #{map.get(map.get($theme, 'page'), 'foreground')};
    --page-scrollbar: #{map.get(map.get($theme, 'page'), 'scrollbar')};
    --primary-background: #{map.get(map.get($theme, 'primary'), 'background')};
    --primary-foreground: #{map.get(map.get($theme, 'primary'), 'foreground')};
    --primary-scrollbar: #{map.get(map.get($theme, 'primary'), 'scrollbar')};
    --secondary-background: #{map.get(map.get($theme, 'secondary'), 'background')};
    --secondary-foreground: #{map.get(map.get($theme, 'secondary'), 'foreground')};
    --secondary-scrollbar: #{map.get(map.get($theme, 'secondary'), 'scrollbar')};
    --block-background: #{map.get(map.get($theme, 'block'), 'background')};
    --block-foreground: #{map.get(map.get($theme, 'block'), 'foreground')};
    --block-scrollbar: #{map.get(map.get($theme, 'block'), 'scrollbar')};
    --pageTitle-background: #{map.get(map.get($theme, 'pageTitle'), 'background')};
    --pageTitle-foreground: #{map.get(map.get($theme, 'pageTitle'), 'foreground')};
    --mainMenu-background: #{map.get(map.get($theme, 'mainMenu'), 'background')};
    --mainMenu-backgroundGradient: #{map.get(map.get($theme, 'mainMenu'), 'backgroundGradient')};
    --mainMenu-foreground: #{map.get(map.get($theme, 'mainMenu'), 'foreground')};
    --mainMenu-active-background: #{map.get(map.get(map.get($theme, 'mainMenu'), 'active'), 'background')};
    --mainMenu-active-foreground: #{map.get(map.get(map.get($theme, 'mainMenu'), 'active'), 'foreground')};
    --mainMenu-drawer-background: #{map.get(map.get(map.get($theme, 'mainMenu'), 'drawer'), 'background')};
    --mainMenu-drawer-foreground: #{map.get(map.get(map.get($theme, 'mainMenu'), 'drawer'), 'foreground')};
    --dialog-border: #{map.get(map.get($theme, 'dialog'), 'border')};
    --dialog-title-background: #{map.get(map.get(map.get($theme, 'dialog'), 'title'), 'background')};
    --dialog-title-foreground: #{map.get(map.get(map.get($theme, 'dialog'), 'title'), 'foreground')};
    --dialog-content-background: #{map.get(map.get(map.get($theme, 'dialog'), 'content'), 'background')};
    --dialog-content-foreground: #{map.get(map.get(map.get($theme, 'dialog'), 'content'), 'foreground')};
    --dialog-closeButton-hover-background: #{map.get(map.get(map.get(map.get($theme, 'dialog'), 'closeButton'), 'hover'), 'background')};
    --dialog-closeButton-hover-foreground: #{map.get(map.get(map.get(map.get($theme, 'dialog'), 'closeButton'), 'hover'), 'foreground')};
    --input-background: #{map.get(map.get($theme, 'input'), 'background')};
    --input-foreground: #{map.get(map.get($theme, 'input'), 'foreground')};
    --input-border: #{map.get(map.get($theme, 'input'), 'border')};
    --input-groupAddon-background: #{map.get(map.get(map.get($theme, 'input'), 'groupAddon'), 'background')};
    --input-groupAddon-foreground: #{map.get(map.get(map.get($theme, 'input'), 'groupAddon'), 'foreground')};
    --input-invalid-border: #{map.get(map.get(map.get($theme, 'input'), 'invalid'), 'border')};
    --input-error-background: #{map.get(map.get(map.get($theme, 'input'), 'error'), 'background')};
    --input-error-foreground: #{map.get(map.get(map.get($theme, 'input'), 'error'), 'foreground')};
    --input-error-outline: #{map.get(map.get(map.get($theme, 'input'), 'error'), 'outline')};
    --label-invalid-background: #{map.get(map.get(map.get($theme, 'label'), 'invalid'), 'background')};
    --label-invalid-foreground: #{map.get(map.get(map.get($theme, 'label'), 'invalid'), 'foreground')};
    --select-tag-background: #{map.get(map.get(map.get($theme, 'select'), 'tag'), 'background')};
    --select-tag-foreground: #{map.get(map.get(map.get($theme, 'select'), 'tag'), 'foreground')};
    --grid-evenRow-background: #{map.get(map.get(map.get($theme, 'grid'), 'evenRow'), 'background')};
    --grid-evenRow-foreground: #{map.get(map.get(map.get($theme, 'grid'), 'evenRow'), 'foreground')};
    --grid-oddRow-background: #{map.get(map.get(map.get($theme, 'grid'), 'oddRow'), 'background')};
    --grid-oddRow-foreground: #{map.get(map.get(map.get($theme, 'grid'), 'oddRow'), 'foreground')};
    --grid-highlight-header-background: #{map.get(map.get(map.get(map.get($theme, 'grid'), 'highlight'), 'header'), 'background')};
    --grid-highlight-header-foreground: #{map.get(map.get(map.get(map.get($theme, 'grid'), 'highlight'), 'header'), 'foreground')};
    --grid-highlight-evenRow-background: #{map.get(map.get(map.get(map.get($theme, 'grid'), 'highlight'), 'evenRow'), 'background')};
    --grid-highlight-evenRow-foreground: #{map.get(map.get(map.get(map.get($theme, 'grid'), 'highlight'), 'evenRow'), 'foreground')};
    --grid-highlight-oddRow-background: #{map.get(map.get(map.get(map.get($theme, 'grid'), 'highlight'), 'oddRow'), 'background')};
    --grid-highlight-oddRow-foreground: #{map.get(map.get(map.get(map.get($theme, 'grid'), 'highlight'), 'oddRow'), 'foreground')};
    --separator-background: #{map.get(map.get($theme, 'separator'), 'background')};
    --separator-foreground: #{map.get(map.get($theme, 'separator'), 'foreground')};
    --level-succes: #{map.get(map.get($theme, 'level'), 'succes')};
    --level-info: #{map.get(map.get($theme, 'level'), 'info')};
    --level-warning: #{map.get(map.get($theme, 'level'), 'warning')};
    --level-error: #{map.get(map.get($theme, 'level'), 'error')};
    --level-default: #{map.get(map.get($theme, 'level'), 'default')};
    --weight-foreground: #{map.get(map.get($theme, 'weight'), 'foreground')};
    --weight-weight-0: #{map.get(map.get($theme, 'weight'), 'weight-0')};
    --weight-weight-1: #{map.get(map.get($theme, 'weight'), 'weight-1')};
    --weight-weight-2: #{map.get(map.get($theme, 'weight'), 'weight-2')};
    --weight-weight-3: #{map.get(map.get($theme, 'weight'), 'weight-3')};
    --weight-weight-4: #{map.get(map.get($theme, 'weight'), 'weight-4')};
    --weight-weight-5: #{map.get(map.get($theme, 'weight'), 'weight-5')};
    --paging-onPrimary-background: #{map.get(map.get(map.get($theme, 'paging'), 'onPrimary'), 'background')};
    --paging-onPrimary-foreground: #{map.get(map.get(map.get($theme, 'paging'), 'onPrimary'), 'foreground')};
    --paging-onPrimary-disabled-background: #{map.get(map.get(map.get(map.get($theme, 'paging'), 'onPrimary'), 'disabled'), 'background')};
    --paging-onPrimary-disabled-foreground: #{map.get(map.get(map.get(map.get($theme, 'paging'), 'onPrimary'), 'disabled'), 'foreground')};
    --paging-onPrimary-highlight-background: #{map.get(map.get(map.get(map.get($theme, 'paging'), 'onPrimary'), 'highlight'), 'background')};
    --paging-onPrimary-highlight-foreground: #{map.get(map.get(map.get(map.get($theme, 'paging'), 'onPrimary'), 'highlight'), 'foreground')};
    --paging-onPrimary-highlight-disabled-background: #{map.get(map.get(map.get(map.get(map.get($theme, 'paging'), 'onPrimary'), 'highlight'), 'disabled'), 'background')};
    --paging-onPrimary-highlight-disabled-foreground: #{map.get(map.get(map.get(map.get(map.get($theme, 'paging'), 'onPrimary'), 'highlight'), 'disabled'), 'foreground')};
    --paging-onSecondary-background: #{map.get(map.get(map.get($theme, 'paging'), 'onSecondary'), 'background')};
    --paging-onSecondary-foreground: #{map.get(map.get(map.get($theme, 'paging'), 'onSecondary'), 'foreground')};
    --paging-onSecondary-highlight-background: #{map.get(map.get(map.get(map.get($theme, 'paging'), 'onSecondary'), 'highlight'), 'background')};
    --paging-onSecondary-highlight-foreground: #{map.get(map.get(map.get(map.get($theme, 'paging'), 'onSecondary'), 'highlight'), 'foreground')};
    --alert-info-background: #{map.get(map.get(map.get($theme, 'alert'), 'info'), 'background')};
    --alert-info-foreground: #{map.get(map.get(map.get($theme, 'alert'), 'info'), 'foreground')};
    --alert-warning-background: #{map.get(map.get(map.get($theme, 'alert'), 'warning'), 'background')};
    --alert-warning-foreground: #{map.get(map.get(map.get($theme, 'alert'), 'warning'), 'foreground')};
    --alert-success-background: #{map.get(map.get(map.get($theme, 'alert'), 'success'), 'background')};
    --alert-success-foreground: #{map.get(map.get(map.get($theme, 'alert'), 'success'), 'foreground')};
    --alert-danger-background: #{map.get(map.get(map.get($theme, 'alert'), 'danger'), 'background')};
    --alert-danger-foreground: #{map.get(map.get(map.get($theme, 'alert'), 'danger'), 'foreground')};
    --alert-error-background: #{map.get(map.get(map.get($theme, 'alert'), 'error'), 'background')};
    --alert-error-foreground: #{map.get(map.get(map.get($theme, 'alert'), 'error'), 'foreground')};
    --text-info-background: #{map.get(map.get(map.get($theme, 'text'), 'info'), 'background')};
    --text-info-foreground: #{map.get(map.get(map.get($theme, 'text'), 'info'), 'foreground')};
    --text-warning-background: #{map.get(map.get(map.get($theme, 'text'), 'warning'), 'background')};
    --text-warning-foreground: #{map.get(map.get(map.get($theme, 'text'), 'warning'), 'foreground')};
    --text-success-background: #{map.get(map.get(map.get($theme, 'text'), 'success'), 'background')};
    --text-success-foreground: #{map.get(map.get(map.get($theme, 'text'), 'success'), 'foreground')};
    --text-danger-background: #{map.get(map.get(map.get($theme, 'text'), 'danger'), 'background')};
    --text-danger-foreground: #{map.get(map.get(map.get($theme, 'text'), 'danger'), 'foreground')};
    --button-info-background: #{map.get(map.get(map.get($theme, 'button'), 'info'), 'background')};
    --button-info-foreground: #{map.get(map.get(map.get($theme, 'button'), 'info'), 'foreground')};
    --button-warning-background: #{map.get(map.get(map.get($theme, 'button'), 'warning'), 'background')};
    --button-warning-foreground: #{map.get(map.get(map.get($theme, 'button'), 'warning'), 'foreground')};
    --button-success-background: #{map.get(map.get(map.get($theme, 'button'), 'success'), 'background')};
    --button-success-foreground: #{map.get(map.get(map.get($theme, 'button'), 'success'), 'foreground')};
    --button-danger-background: #{map.get(map.get(map.get($theme, 'button'), 'danger'), 'background')};
    --button-danger-foreground: #{map.get(map.get(map.get($theme, 'button'), 'danger'), 'foreground')};
    --button-primary-background: #{map.get(map.get(map.get($theme, 'button'), 'primary'), 'background')};
    --button-primary-foreground: #{map.get(map.get(map.get($theme, 'button'), 'primary'), 'foreground')};
    --button-default-background: #{map.get(map.get(map.get($theme, 'button'), 'default'), 'background')};
    --button-default-foreground: #{map.get(map.get(map.get($theme, 'button'), 'default'), 'foreground')};
    --button-error-background: #{map.get(map.get(map.get($theme, 'button'), 'error'), 'background')};
    --button-error-foreground: #{map.get(map.get(map.get($theme, 'button'), 'error'), 'foreground')};
    --button-icon-background: #{map.get(map.get(map.get($theme, 'button'), 'icon'), 'background')};
    --button-icon-foreground: #{map.get(map.get(map.get($theme, 'button'), 'icon'), 'foreground')};
    --button-icon-hover-background: #{map.get(map.get(map.get(map.get($theme, 'button'), 'icon'), 'hover'), 'background')};
    --button-icon-hover-foreground: #{map.get(map.get(map.get(map.get($theme, 'button'), 'icon'), 'hover'), 'foreground')};
}