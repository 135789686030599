@use '../vars';

@mixin css
{
    //############## ALERTS ##############

    .alert
    {
        padding: 6px 8px;
        border-radius: vars.$borderRadius;
        border-width: 1px;
        margin-top: 10px;
        margin-right: 10px;
        border-style: solid;
    }

    @include generate-alert(('info', 'warning', 'success', 'danger', 'error'));
}

@mixin generate-alert($alerts)
{
    @each $alert in $alerts
    {
        .alert-#{$alert}
        {
            background-color: var(--alert-#{$alert}-background);
            backdrop-filter: brigthness(86%);
            color: var(--alert-#{$alert}-foreground);
            border-color: var(--alert-#{$alert}-background);
        }
    }
}