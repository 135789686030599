//---------------------- SIZING ----------------------
$sizeXs: .286rem; //4px
$sizeSm: .714rem; //10px
$sizeMd: 1.071rem; //15px
$sizeLg: 1.429rem; //20px
$sizeBg: $sizeLg;

$negXsWidth: -.286rem;
$negSmWidth: -.714rem;
$negMdWidth: -1.071rem;
$negLgWidth: -1.429rem;

$rootMinFontSize: 12px;
$rootMaxFontSize: 14px;
$rootMinFontSizeWidth: 500px;
$rootMaxFontSizeWidth: 800px;

$responsiveThresholdMd: 800px;

$borderRadius: 4px;

$sizes: (none: 0, extra-small: $sizeXs, small: $sizeSm, medium: $sizeMd, big: $sizeLg);