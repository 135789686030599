@use 'sass:math';
@use 'vars';

@mixin thin-scrollbar-color($color)
{
    .thin-scrollbar
    {
        scrollbar-color: var($color) transparent;
    }

    .thin-scrollbar::-webkit-scrollbar-thumb
    {
        background-color: var($color);
    }

    .thin-scrollbar::-webkit-scrollbar-track
    {
        background-color: transparent;
    }

    .thin-scrollbar::-webkit-scrollbar
    {
        background-color: transparent;
    }
}

@function strip-unit($value)
{
    @return math.div($value, ($value * 0 + 1));
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) 
{
    $u1: math.unit($min-vw);
    $u2: math.unit($max-vw);
    $u3: math.unit($min-font-size);
    $u4: math.unit($max-font-size);

    @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 
    {
        & 
        {
            font-size: $min-font-size;

            @media screen and (min-width: $min-vw) 
            {
                font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }
            @media screen and (min-width: $max-vw) 
            {
                font-size: $max-font-size;
            }
        }
    }
}

@mixin buildSizes()
{
    --size-xs: #{vars.$sizeXs};
    --size-sm: #{vars.$sizeSm};
    --size-md: #{vars.$sizeMd};
    --size-lg: #{vars.$sizeLg};
    --size-bg: #{vars.$sizeBg};

    --size-neg-xs: #{vars.$negXsWidth};
    --size-neg-sm: #{vars.$negSmWidth};
    --size-neg-md: #{vars.$negMdWidth};
    --size-neg-lg: #{vars.$negLgWidth};

    --border-radius: #{vars.$borderRadius};
    --responsive-threshold-md: #{vars.$responsiveThresholdMd};
}