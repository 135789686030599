@use '../vars';

@mixin css
{
    //############## BUTTONS ##############

    %btn-base
    {
        letter-spacing: 1px;
        background-image: none;
        text-shadow: 0 0;
        box-shadow: 0 0;
        text-transform: uppercase;
        transition: all 350ms;
        border: none;
        border-radius: vars.$borderRadius;
        padding: 5px 10px;
        font-family: "Inter";
        cursor: pointer;
        white-space: nowrap;
        font-size: 0.95em;
        position: relative;
    }

    .btn
    {
        @extend %btn-base;

        &.disabled,
        &:disabled
        {
            opacity: 0.75;
            cursor: not-allowed;
        }

        &>span
        {
            position: relative;
        }

        span[class^="fa"],
        span[class^="fas"],
        span[class^="fab"],
        span[class^="glyphicon"]
        {
            margin-right: vars.$sizeXs;
        }
    }

    multi-button.btn
    {
        padding: 0;

        button
        {
            @extend %btn-base;
        }
    }

    .btn-icon
    {
        color: var(--button-icon-foreground);
        border: none;
        padding: 0;
        margin: 0;
        background-color: transparent;
        cursor: pointer;
        user-select: none;
        -webkit-user-select: none;

        &:hover,
        &:focus
        {
            color: var(--button-icon-hover-foreground);

            .double-right-icon
            {
                div
                {
                    border-color: var(--button-icon-hover-foreground);
                }
            }
        }

        &:active
        {
            color: var(--button-icon-hover-foreground);
            transform: scale(105%);
        }
    }

    @include generate-button(('primary', 'info', 'default', 'success', 'warning', 'danger', 'error'));
}

@mixin generate-button($buttonNames)
{
    @each $buttonName in $buttonNames
    {
        .btn-#{$buttonName}
        {
            &::before 
            {
                content: "";
                position: absolute;
                transition: all 350ms;
                top: 0; left: 0;
                width: 100%; height: 100%;
                background-color: var(--button-#{$buttonName}-background);
                border-radius: vars.$borderRadius;
            }

            &:hover:not(:is(.disabled, :disabled)),
            &:focus:not(:is(.disabled, :disabled))
            {
                &::before 
                {
                    filter: brightness(92%);
                }
            }

            &:active:not(:is(.disabled, :disabled))
            {
                &::before 
                {
                    filter: brightness(85%);
                }

                &>span[class^="fa"],
                &>span[class^="fas"],
                &>span[class^="glyphicon"]
                {
                    filter: brightness(70%);
                }
            }

            color: var(--button-#{$buttonName}-foreground);

            &>span
            {
                color: var(--button-#{$buttonName}-foreground);
            }

            &>span[class^="fa"],
            &>span[class^="fas"],
            &>span[class^="glyphicon"]
            {
                transition: all 350ms;
                color: var(--button-#{$buttonName}-background);
                filter: brightness(80%);
            }
        }
    }
}